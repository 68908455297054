html, body {
  font-family: Lato;
  min-height: 100%;
}

*:not(.fa) {
  font-family: Lato;
}

.btn-hover:hover {
  background-color: #FF5656 !important;
  outline: none;
} 

.btn-hover:disabled {
  background-color: #e0e5d9 !important;
  color: #000000 !important;
  outline: none;
} 

.MuiPaper-root {
  background-color: grey !important;
}

.dialog .MuiPaper-root {
  background-color: white !important;
}

#root {
  min-height: 100%;
  color: #000000; }

a {
  text-decoration: none !important; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 100%;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px; }

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd; }

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc; }

.react-autosuggest__section-container--first {
  border-top: 0; }

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777; }

.cursor-pointer {
  cursor: pointer; }

.react-phone-number-input__icon {
  border: none !important; }
  .react-phone-number-input__icon > img {
    vertical-align: unset;
    border: grey solid 1px; }

@media (min-width: 1180px) {
  .offer__banner__width {
    max-width: 1440px !important; } }

.mailChimp__subscribe_btn {
  background-color: #ec1d24;
  padding: 10px 30px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: none; }

@media (min-width: 1140px) {
  .mailChimp__info_mod {
    max-height: 25em;
    max-width: 50vw; } }

@media (min-width: 769px) and (max-width: 1139px) {
  .mailChimp__info_mod {
    max-height: 30em;
    max-width: 60vw; } }

@media (max-width: 768px) {
  .mailChimp__info_mod {
    max-height: 80vh;
    max-width: 98vw; } }

.mailChimp__info_mod_header {
  text-align: center; }

.blog__ym_like-this, .mailChimp__info_mod_header {
  font-family: Cormorant Garamond,serif; }

.container-dialog {
  background-color:transparent;
  width: 100%;
  height: 100%;
  position: fixed;
  left:0vw;
  right: 0vw;
  z-index: 20;
  justify-content: center;
  display: flex;
  align-content: center;
   
}

.dialogbox {
  background-color: #F8F8F8F7;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}

.modal-title h1 {
  font-size: 20px;
  margin: unset;
}

.modal-body .my-lg-5 {
  margin-top:unset;
  margin-top: 1rem !important;
}

.modal-body .medium-name{
  margin-right:unset;
  margin-right: 3rem !important;
}





.bottom-bar {
  position:fixed;
  left: 0vw;
  bottom: 0px;
  right: 0vw;
  z-index: 20;
  display: flex;
  flex-direction: column;
  pointer-events: none; 
  /* text-align: right;
  justify-content: end; */
}

@-webkit-keyframes aboutFadeIn { 
  0% { 
  opacity: 0;
  -webkit-transform: translate3d(0, -5%, 0);
          transform: translate3d(0, -5%, 0);
  } 
  100% { 
  opacity: 0.9;
  -webkit-transform: none;
          transform: none;
  } 
}

@keyframes aboutFadeIn { 
  0% { 
  opacity: 0;
  -webkit-transform: translate3d(0, -5%, 0);
          transform: translate3d(0, -5%, 0);
  } 
  100% { 
  opacity: 0.9;
  -webkit-transform: none;
          transform: none;
  } 
}


.background-bottom {
    -webkit-animation: aboutFadeIn ease 0.5s;
            animation: aboutFadeIn ease 0.5s;

    background-color: #141d60;
    opacity: 0.9;
    padding: 15px;
    width: 100%;
    align-self: center;
    align-content: center;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    pointer-events: auto; 
    min-height: 70px;
  }
  
  .bottom-button {
    margin-right: 20px;
    text-align: center;
    font-size: 18px;
  }

  .bottom-title {
    font-family: 'Cormorant Garamond,serif';
    color: #ffffff;
    font-size: 20px;
    margin-left: 20px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .bottom-subtitle {
    font-family: 'Cormorant Garamond,serif';
    color: #ffffff;
    font-size:16px;
    margin-left: 20px;
    font-weight: 400;
    max-width: 75vw;
    letter-spacing: 1px;
  }

  .floating-button {
    align-self: flex-end;
    object-position: top right;
    float: inline-end;
    float: right;
    pointer-events: auto; 
    cursor: pointer;
  }

  .floating-info {

    background-color: #F8F8F8F7;
    width: 300px;
    font-size: 18px;
    padding: 5px 20px 5px 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    float: right;
    box-shadow: 0px 3px 6px #00000029;
    font-family: Cormorant Garamond,serif;
    pointer-events: auto; 

   

  }

  .modal {
    z-index: 1500 !important;
  }

  .floating-info .description{
    font-family: 'Cormorant Garamond,serif';
    color: #1C1C1C;
    font-size: 18px;
  }

  .floating-info .learn-more{
    font-size: 16px;
  }

  .floating-info a{
    color: #EC1D24;
  }

  .floating-info .icon {
    position: absolute;
    top: 2px;
    font-size: 14px;
    font-weight: normal;
    right: 22px;
    margin: 3px;
  }


  @media (max-width: 767px) {
   
      

      .floating-info .description{
        font-size: 14px;
      }

      .floating-info .learn-more{
        font-size: 14px;
      }

      .modal-title  h1 {
        font-size: 16px;
      }

      .floating-button {
        
        height: 75px;
        width: 75px;
        
      }

      .modal-body .medium-name{
        margin-right:unset;
        margin-right: 1rem !important;
      }

      .bottom-subtitle {
        font-size: 12px;
        max-width: 65vw;;
     }
      
  }

  @media (max-width: 1139px) {

    .bottom-button {
      margin-right: 15px;
      vertical-align: middle;
      padding: 10px 17px !important;
      text-align: center;  
      font-size: 14px; 
      min-width: 103px;
    }

    .background-bottom {
      padding: 7px;
    }
   
    

    .bottom-title {
     font-size: 14px;
     padding-right: 5px;
  }
   

  .bottom-subtitle {
    font-size: 12px;
 }
  

    
}


/* 
.bottom-title {

    padding: auto 20px;
    font-weight: 500;
    color: white;
    text-align: center

} */




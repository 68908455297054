html, body {
  font-family: Lato;
  min-height: 100%;
}

*:not(.fa) {
  font-family: Lato;
}

.btn-hover:hover {
  background-color: #FF5656 !important;
  outline: none;
} 

.btn-hover:disabled {
  background-color: #e0e5d9 !important;
  color: #000000 !important;
  outline: none;
} 

.MuiPaper-root {
  background-color: grey !important;
}

.dialog .MuiPaper-root {
  background-color: white !important;
}

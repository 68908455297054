#root {
  min-height: 100%;
  color: #000000;
}

a {
	text-decoration: none !important;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 100%;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

.cursor-pointer {
  cursor: pointer;
}

.react-phone-number-input__icon {
  border: none !important;
  & > img {
    vertical-align: unset;
    border: grey solid 1px;
  }
}


@media (min-width: 1180px) {
  .offer__banner__width{
    max-width: 1440px !important;
  }
}
// Mailchimp subscribe button style

.mailChimp__subscribe_btn{
  background-color: #ec1d24;
  padding: 10px 30px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: none;
}


// Mailchimp Modal styles

@media (min-width: 1140px) {
  .mailChimp__info_mod{
    max-height: 25em;
    max-width: 50vw;
  }
}
@media (min-width: 769px) and (max-width: 1139px) {
  .mailChimp__info_mod{
    max-height: 30em;
    max-width: 60vw;
  }
}
@media (max-width: 768px) {
  .mailChimp__info_mod{
    max-height: 80vh;
    max-width: 98vw;
  }
}

.mailChimp__info_mod_header{
  text-align: center;
}

.blog__ym_like-this, .mailChimp__info_mod_header{
  font-family: Cormorant Garamond,serif;
}